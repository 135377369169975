<template>
  <b-card>
    <g-form>
      <b-row>
        <!-- fromDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
            name="fromDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
            name="toDate"
          />
        </b-col>
        <!-- Accounts -->
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.accountId"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :options="subAccounts"
            label-text="accountName"
            field="select"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.costCenterId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="costCenter"
              field="select"
              name="costCenter"
              :options="subCostcenters"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.groupBy"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="groupBy"
              field="select"
              name="groupBy"
              rules="required"
              :options="costCenterGroupByList"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-1"
            variant="relief-primary"
            @click="print('total')"
          >
            <feather-icon
              class="mr-25"
              icon="ListIcon"
            />
            {{ $t('printTotal') }}
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            @click="print('details')"
          >
            <feather-icon
              class="mr-25"
              icon="LoaderIcon"
            />
            {{ $t('printDetails') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { costCenterGroupByList } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
   mixins: [
    reportMixin,
  ],

  props: {
    type: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      subAccounts: [],
      subCostcenters: [],
      costCenterGroupByList: costCenterGroupByList,
      selectedItem: {
        accountId: null,
        costCenterId: null,
        groupBy: null,
        fromDate: this.today,
        toDate: this.today
      },
    };
  },

  beforeMount() {
    this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
    this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
  },

  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getAccounts();
  },

  methods: {
    getAccounts() {
      this.get({ url: 'aggregates/accounts' }).then((data) => {
        data.accounts.forEach(element => {
            element.arabicName = `${element.code} - ${element.arabicName}`;
            element.englishName = `${element.code} - ${element.englishName}`;
          });
          data.costCenters.forEach(element => {
            element.arabicName = `${element.code} - ${element.arabicName}`;
            element.englishName = `${element.code} - ${element.englishName}`;
          });
        this.subAccounts = data.accounts;
        this.subCostcenters = data.costCenters;
      });
    },
    getDefaultDateIfNotExists() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
      }
    },
    validateBeforePrint() {
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return false;
      if (this.selectedItem.fromDate > this.selectedItem.toDate) {
          this.doneAlert({ text: this.$t('fromDateAfterToDate'), type: 'warning' });
          return false;
      }
      if (!this.selectedItem.groupBy) return false;
      return true
    },
    getReport(type) {
      switch (type) {
        case 'total':
          return this.isRight ? 'CostCenterTotal-ar' : 'CostCenterTotal-en'

        case 'details':
          return this.isRight ? 'CostCenterDetails-ar' : 'CostCenterDetails-en'

        default:
          return ''
      }
    },
    print(type) {
      this.getDefaultDateIfNotExists();
      if (!this.validateBeforePrint()) return;
      this.printReport(this.getReport(type), this.selectedItem);
    },
  },
};
</script>
